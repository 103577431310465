import React from 'react';
import '../styles/Footer.css';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function Footer() {
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer-container">
      <div className="footer-content">
        <Typography className="company-name">Prime Sketch © {currentYear}</Typography>
        <div className="links">
          <Typography className="footer-link" onClick={() => navigate('/careers')}>Careers</Typography>
          <Typography className="footer-link footer-link-hidden" onClick={() => navigate('/about-us')}>About Us</Typography>
          <Typography className="footer-link footer-link-hidden" onClick={() => navigate('/contact')}>Contact Us</Typography>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
