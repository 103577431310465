import '../styles/Home.css';
import React, { useEffect, useState } from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import CustomSlider from './CustomSlider';
import { Grid, Typography, Paper, Button, Container } from '@mui/material';
import LogoSlogan from '../assets/logo-slogan.png';
import { useNavigate } from 'react-router-dom';
import useGet from './handlers/useGet';
import Loading from './Loading';
import ClientsSlider from './ClientsSlider';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: 'transparent',
  textAlign: 'center',
  position: 'relative',
  overflow: 'hidden',
}));

function Home() {
  const navigate = useNavigate();
  const [ourServicesList, setOurServicesList] = useState([]);
  const [ourWorkList, setOurWorkList] = useState([]);

  const [sliderData, sliderMessage, sliderErrors, sliderLoading] = useGet('slider/1');
  const [servicesData, servicesMessage, servicesErrors, servicesLoading] = useGet('services');
  const [projectsData, projectsMessage, projectsErrors, projectsLoading] = useGet('projects-categories');
  const [certificatesData, certificatesMessage, certificatesErrors, certificatesLoading] = useGet('certificates');
  const [clientsData, clientsMessage, clientsErrors, clientsLoading] = useGet('clients');

  const [itemToRender, setItemToRender] = useState('loading');

  const [slidesList, setSlidesList] = useState([]);
  const [servicesList, setServicesList] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [certificatesList, setCertificatesList] = useState([]);
  const [clientsList, setClientsList] = useState([]);
  const [errorMessages, setErrorMessages] = useState({});

  useEffect(() => {
    setItemToRender('loading');
    if (!sliderLoading && !servicesLoading && !projectsLoading && !certificatesLoading && !clientsLoading) {
      if (sliderData && servicesData && projectsData && certificatesData && clientsData) {
        setSlidesList(sliderData);
        setServicesList(servicesData);
        setCategoriesList(projectsData);
        setCertificatesList(certificatesData);
        setClientsList(clientsData);
        setItemToRender('data');
      } else {
        setItemToRender('error');
        setErrorMessages({ general: 'An unexpected error occurred while fetching data from the server. Please try again later.' });
      }
    }
  }, [sliderLoading, servicesLoading, projectsLoading, certificatesLoading, sliderData, servicesData, projectsData, certificatesData]);

  useEffect(() => {
    if (servicesList) {
      const filteredServices = servicesList.filter(service => service.show_on_homepage);
      setOurServicesList(filteredServices);
    }

    if (categoriesList) {
      const filteredCategories = categoriesList.filter(category => category.show_on_homepage);
      setOurWorkList(filteredCategories);
    }
  }, [categoriesList, servicesList]);

  if (itemToRender === 'loading') {
    return (
      <div className="page-container">
        <Loading />
      </div>
    );
  } else if (itemToRender === 'error') {
    return (
      <div className="error-container-outer">
        <Paper className="error-container">
          <Typography className="error-message" color="error" gutterBottom>
            {Object.values(errorMessages).flat().join(', ')}
          </Typography>
          <Button className="return-button" onClick={() => setItemToRender('data')}>
            Return
          </Button>
        </Paper>
      </div>
    );
  }

  return (
    <>
      <CustomSlider slides={slidesList} isFullScreen={true} SliderText={''} Slogan={LogoSlogan} arrows={true}/>
      <div className="services-section">
        <Typography className="title mb-2">Our Services</Typography>
        <Grid container spacing={2} className="grid-container">
          {ourServicesList.map(service => (
            <Grid item xs={12} sm={6} md={4} key={service.id}>
              <Item key={service.id} elevation={3} className="service-item " onClick={() => navigate(`/services/${service.id}`, { state: { service } })}>
                <Typography className="item-text" key={service.id}>{service.title}</Typography>
                <img src={`https://prime-sketch.s3.eu-north-1.amazonaws.com/${service.cover_image}`} alt="service" key={service.id}/>
              </Item>
            </Grid>
          ))}
        </Grid>
      </div>
      <div className="projects-section">
        <Typography className="title mt-0">Our Work</Typography>
        <div className="projects-description-container">
          <Typography className="projects-description">
            Our team of seasoned professionals is enthusiastic about their work. <br />
            They will collaborate with you to create environments that mirror your vision and personality,
            while also adhering to your budget.
          </Typography>
          <Button variant="contained" className="projects-button" onClick={() => navigate('/projects')}>
            See our Projects
          </Button>
        </div>
        <Grid container spacing={2} className="grid-container">
          {ourWorkList.map(category => (
            <Grid item xs={12} md={6} lg={4} key={category.id}>
              <Item className="service-item" onClick={() => navigate("/projects", { state: { initialCategory: category.name } })}>
                <Typography className="item-text" key={category.id}>{category.name}</Typography>
                <img src={`https://prime-sketch.s3.eu-north-1.amazonaws.com/${category.cover_image}`} alt="Project" key={category.id}/>
              </Item>
            </Grid>
          ))}
        </Grid>
      </div>
      <div className="clients-section">
        <Typography className="title mt-5">Our Clients</Typography>
        <ClientsSlider clients={clientsList} />
      </div>
      <div className="certificates-section grey-section">
        <Container>
            <Typography className="title title-lg">
                Awards and Certificates
            </Typography>
            <Typography className="title title-sm">
                Certificates
            </Typography>
            <div className="image-gallery">
              <Grid container spacing={12} justifyContent="center" alignItems="center">
                {certificatesList.map((certificate, index) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={certificate.id}>
                        <img key={certificate.id} src={`https://prime-sketch.s3.eu-north-1.amazonaws.com/${certificate.cover_image}`} alt="certificate" className="section-img"/>
                    </Grid>
                ))}
              </Grid>
            </div>
        </Container>
      </div>
    </>
  );
}

export default Home;
