// clientsSlider.jsx
import React from 'react';
import Slider from 'react-slick';
import '../styles/ClientsSlider.css';

const ClientsSlider = ({ clients }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    nextArrow: <button className="clients-arrow clients-right-arrow">&gt;</button>,
    prevArrow: <button className="clients-arrow clients-left-arrow">&lt;</button>,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="clients-slider">
      <Slider {...settings}>
        {clients.map((client, index) => (
          <div key={index} className="clients-slide-container">
            <img
              src={`https://prime-sketch.s3.eu-north-1.amazonaws.com/${client.cover_image}`}
              alt={`Client ${index}`}
              className="clients-slide"
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ClientsSlider;
