import React from 'react';
import '../styles/LoadingStyles.css';
import LogoSlogan from '../assets/logo-slogan.png';

function Loading() {

    return (
        <div className="loading-container">
            <div className="auth-logo-container">
                <img src={LogoSlogan} alt="logo" width={900} />
            </div>
            <div className="spinner-container">
                <div className="spinner-border" role="status">
                    <span className="visually-hidden main-text">Loading</span>
                </div>
            </div>
        </div>
    );
}

export default Loading;