import axios from "axios";
import { useState, useEffect } from "react";

function getToken() {
  return localStorage.getItem('token');
}


async function fetchData(field) {
  const token = getToken();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
  try {
    const response = await axios.get(`https://api.primesketch.net/api/${field}`,config);
    return response.data;
  } catch (e) {
    throw e;
  }
}

function useGet(field) {
  const [data, setData] = useState([]);
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData(field)
      .then((response) => {
        setData(response);
        setMessage(response.message);
        setLoading(false);
      })
      .catch((e) => {
        setErrors([e]);
        setLoading(false);
      });
  }, [field]);

  return [data, message, errors, loading];
}

export default useGet;