import '../styles/Header.css';
import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import logo from '../assets/logo-simple.png';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';
import useGet from './handlers/useGet';

function Header() {
  const [scrolled, setScrolled] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const [projectsCategoriesData] = useGet('projects-categories');
  const [servicesData] = useGet('services');
  const [productsData] = useGet('products');
  const [projectsCategoriesList, setprojectsCategoriesList] = useState([]);
  const [servicesList, setServicesList] = useState([]);
  const [productsList, setProductsList] = useState([]);
  const [sortedCategories, setSortedCategories] = useState([]);

  useEffect(() => {
    if (projectsCategoriesData && servicesData && productsData) {
      setprojectsCategoriesList(projectsCategoriesData);
      setSortedCategories([...projectsCategoriesList].sort((a, b) => a.index - b.index));
      setServicesList(servicesData);
      setProductsList(productsData);
    }
  }, [productsData, projectsCategoriesData, projectsCategoriesList, servicesData]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      setScrolled(scrollTop > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleNavLinkClick = (category) => {
    navigate('/projects', { state: { initialCategory: category } });
    window.scrollTo(0, 0);
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const isActiveRoute = (route) => location.pathname.startsWith(route);

  return (
    <AppBar className={`header ${scrolled ? 'scrolled' : ''}`} position="fixed">
      <Toolbar className="toolbar">
        <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)} className="menu-icon">
          <MenuIcon />
        </IconButton>
        <img src={logo} alt="logo" className="logo" width={75} />
        <div className="nav-links px-4">
          <NavLink exact to="/" onClick={() => window.scrollTo(0, 0)} className={({ isActive }) => (isActive ? 'navlink active' : 'navlink')}>
            Home
          </NavLink>
          <NavLink to="/about-us" onClick={() => window.scrollTo(0, 0)} className={({ isActive }) => (isActive ? 'navlink active' : 'navlink')}>
            About Us
          </NavLink>
          <div className="navitem-with-dropdown">
            <NavLink to="/projects" onClick={() => window.scrollTo(0, 0)} className={({ isActive }) => (isActive ? 'navlink active' : 'navlink')}>
              Projects
            </NavLink>
            {!isActiveRoute('/projects') && (
              <div className="dropdown">
                <div className="dropdown-content">
                  {sortedCategories.map((category) => (
                    <NavLink
                      key={category.id}
                      state={{ initialCategory: category.name }}
                      to="/projects"
                      onClick={() => {
                        handleNavLinkClick(category.name);
                        setDrawerOpen(false);
                      }}
                      className="dropdown-navlink"
                    >
                      {category.name}
                    </NavLink>
                  ))}
                </div>
              </div>
            )}
          </div>
          <div className="navitem-with-dropdown">
            <NavLink to="/services" onClick={() => window.scrollTo(0, 0)} className={({ isActive }) => (isActive ? 'navlink active' : 'navlink')}>
              Services
            </NavLink>
            {!isActiveRoute('/services') && (
              <div className="dropdown">
                <div className="dropdown-content">
                  {servicesList.map((service) => (
                    <NavLink
                      key={service.id}
                      state={{ service }}
                      to={`/services/${service.id}`}
                      onClick={() => {
                        handleNavLinkClick();
                        setDrawerOpen(false);
                      }}
                      className="dropdown-navlink"
                    >
                      {service.title}
                    </NavLink>
                  ))}
                </div>
              </div>
            )}
          </div>
          <div className="navitem-with-dropdown">
            <NavLink to="/products" onClick={() => window.scrollTo(0, 0)} className={({ isActive }) => (isActive ? 'navlink active' : 'navlink')}>
              Products
            </NavLink>
            {!isActiveRoute('/products') && (
              <div className="dropdown">
                <div className="dropdown-content">
                  {productsList.map((product) => (
                    <NavLink
                      key={product.id}
                      state={{ product }}
                      to={`/products/${product.id}`}
                      onClick={() => {
                        handleNavLinkClick();
                        setDrawerOpen(false);
                      }}
                      className="dropdown-navlink"
                    >
                      {product.name}
                    </NavLink>
                  ))}
                </div>
              </div>
            )}
          </div>
          <NavLink to="/contact" onClick={() => {
            handleNavLinkClick();
            setDrawerOpen(false);
          }} className={({ isActive }) => (isActive ? 'navlink active' : 'navlink')}>
            Contact Us
          </NavLink>
        </div>
      </Toolbar>
      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
        <div
          className="drawer-content"
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <NavLink exact to="/" onClick={() => {
            window.scrollTo(0, 0);
            setDrawerOpen(false);
          }} className={({ isActive }) => (isActive ? 'navlink active' : 'navlink')}>
            Home
          </NavLink>
          <NavLink to="/about-us" onClick={() => {
            window.scrollTo(0, 0);
            setDrawerOpen(false);
          }} className={({ isActive }) => (isActive ? 'navlink active' : 'navlink')}>
            About Us
          </NavLink>
          <div className="navitem-with-dropdown">
            <NavLink to="/projects" onClick={() => {
              window.scrollTo(0, 0);
              setDrawerOpen(false);
            }} className={({ isActive }) => (isActive ? 'navlink active' : 'navlink')}>
              Projects
            </NavLink>
            {!isActiveRoute('/projects') && (
              <div className="dropdown">
                <div className="dropdown-content">
                  {projectsCategoriesList.map((category) => (
                    <NavLink
                      key={category.id}
                      state={{ initialCategory: category.name }}
                      to="/projects"
                      onClick={() => {
                        window.scrollTo(0, 0);
                        setDrawerOpen(false);
                      }}
                      className="dropdown-navlink"
                    >
                      {category.name}
                    </NavLink>
                  ))}
                </div>
              </div>
            )}
          </div>
          <div className="navitem-with-dropdown">
            <NavLink to="/services" onClick={() => {
              window.scrollTo(0, 0);
              setDrawerOpen(false);
            }} className={({ isActive }) => (isActive ? 'navlink active' : 'navlink')}>
              Services
            </NavLink>
            {!isActiveRoute('/services') && (
              <div className="dropdown">
                <div className="dropdown-content">
                  {servicesList.map((service) => (
                    <NavLink
                      key={service.id}
                      state={{ service }}
                      to={`/services/${service.id}`}
                      onClick={() => {
                        window.scrollTo(0, 0);
                        setDrawerOpen(false);
                      }}
                      className="dropdown-navlink"
                    >
                      {service.title}
                    </NavLink>
                  ))}
                </div>
              </div>
            )}
          </div>
          <div className="navitem-with-dropdown">
            <NavLink to="/products" onClick={() => {
              window.scrollTo(0, 0);
              setDrawerOpen(false);
            }} className={({ isActive }) => (isActive ? 'navlink active' : 'navlink')}>
              Products
            </NavLink>
            {!isActiveRoute('/products') && (
              <div className="dropdown">
                <div className="dropdown-content">
                  {productsList.map((product) => (
                    <NavLink
                      key={product.id}
                      state={{ product }}
                      to={`/products/${product.id}`}
                      onClick={() => {
                        window.scrollTo(0, 0);
                        setDrawerOpen(false);
                      }}
                      className="dropdown-navlink"
                    >
                      {product.name}
                    </NavLink>
                  ))}
                </div>
              </div>
            )}
          </div>
          <NavLink to="/contact" onClick={() => {
            window.scrollTo(0, 0);
            setDrawerOpen(false);
          }} className={({ isActive }) => (isActive ? 'navlink active' : 'navlink')}>
            Contact Us
          </NavLink>
        </div>
      </Drawer>
    </AppBar>
  );
}

export default Header;
