import React, { useState, useEffect } from 'react';
import '../styles/Slider.css';

const CustomSlider = ({ slides, isFullScreen, SliderText, Slogan, arrows }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide === slides.length - 1 ? 0 : prevSlide + 1));
    }, 1500); 

    return () => clearInterval(interval);
  }, [slides.length]);

  const goToPreviousSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide === 0 ? slides.length - 1 : prevSlide - 1));
  };

  const goToNextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide === slides.length - 1 ? 0 : prevSlide + 1));
  };

  return (
    <div className={isFullScreen ? 'slider-container-fullscreen' : 'slider-container'}>
      {slides.map((slide, index) => (
        <div key={index} className={`slide ${index === currentSlide ? 'active' : ''}`}>
          <img src={`https://prime-sketch.s3.eu-north-1.amazonaws.com/${slide.path}`} alt={slide.alt} className="slide-image" />
          {Slogan && (
            <div className="slogan-container">
              <img src={Slogan} alt="Slogan" className="slogan-image" />
            </div>
          )}
          {SliderText && <div className="slide-text">{SliderText}</div>}
        </div>
      ))}
      {
        arrows?  
        <>
          <div className="arrow left" onClick={goToPreviousSlide}>{'<'}</div>
          <div className="arrow right" onClick={goToNextSlide}>{'>'}</div>
        </>
        : <></>
      }
    </div>
  );
};

export default CustomSlider;
