import './App.css';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import { lazy, Suspense } from 'react';

import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import FloatingButton from './components/floatingButton/FloatingButton.jsx';

const ServicesHomePage = lazy(() => import('./components/pages/services/ServicesHomePage.jsx'));
const ServicesDetailsPage = lazy(() => import('./components/pages/services/ServicesDetailsPage.jsx'));

const ProjectsHomePage = lazy(() => import('./components/pages/projects/ProjectsHomePage.jsx'));
const ProjectDetailsPage = lazy(() => import('./components/pages/projects/ProjectDetailsPage.jsx'));

const ProductsHomePgae = lazy(() => import('./components/pages/products/ProductsHomePgae.jsx'));
const ProductDetailsPage = lazy(() => import('./components/pages/products/ProductDetailsPage.jsx'));

const AboutUsPage = lazy(() => import('./components/pages/AboutUsPage.jsx'));
const ContactUsPage = lazy(() => import('./components/pages/ContactUsPage.jsx'));

const CareersPage = lazy(() => import('./components/pages/CareersPage.jsx'));

function App() {

  return (
    <Suspense fallback={<div className="container">Loading...</div>}>
      <Router>
        <Header />
        {/* <ScrollToTop /> */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<ServicesHomePage />} />
          <Route path="/services/:id" element={<ServicesDetailsPage />} />
          <Route path="/projects" element={<ProjectsHomePage />} />
          <Route path="/projects/:id" element={<ProjectDetailsPage />} />
          <Route path="/products" element={<ProductsHomePgae />} />
          <Route path="/products/:id" element={<ProductDetailsPage />} />
          <Route path="/about-us" element={<AboutUsPage />} />
          <Route path="/contact" element={<ContactUsPage />} />
          <Route path="/careers" element={<CareersPage />} />
        </Routes>
        <Footer />
        <FloatingButton />
      </Router>
    </Suspense>
  );
}

export default App;
