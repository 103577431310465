
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Fab } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import './FloatingButton.css';
function FloatingButton() {
    const navigate = useNavigate();
    const location = useLocation();

    // Function to handle button click
    const handleClick = () => {
        navigate('/contact');
    };

    // Hide the button on the /contact route
    if (location.pathname === '/contact') {
        return null;
    }

    return (
        <Fab
            color="primary"
            aria-label="contact"
            onClick={handleClick}
            className="floating-button"
        >
            <PhoneIcon />
        </Fab>
    );
}

export default FloatingButton;
